import { useRef, useState } from 'react';
import { wsUrl } from 'config/settings';
import { useDispatch, useSelector } from 'react-redux';
import { changeMcAvtiveCommand, setIsConnectedMc, setList, setSensorList, setStatus } from 'store/mcSlice';
import { v4 as uuidv4 } from 'uuid';

const useWebSocket = () => {
  const socket = useRef(null);
  const dispatch = useDispatch();
  const [isConnected, setIsConnected] = useState(false);
  const path = useSelector((state) => state?.biz?.data?.path);
  let ws = null

  const DATA_TYPE = {
    SENSOR: 's',
    STATUS: 'status',
  }

  const connected = (bizPath) => {
    ws = new WebSocket(`${wsUrl}?bizId=${bizPath}-${uuidv4()}`);
    socket.current = ws;
    
    ws.onopen = () => {
      setIsConnected(true)
      console.log('connected')
    }

    ws.onclose = () => {
      console.log('disConnected')
      // disconnected()
      // reconnected()
      setIsConnected(false)
    }

    // קבלת הודעות מהשרת
    ws.onmessage = (event) => {
      const checkDataType = event?.data?.split('/')[0]
      if(checkDataType === DATA_TYPE.SENSOR) {
        dispatch(setSensorList(event?.data))
      }
      else if(checkDataType === DATA_TYPE.STATUS){
        dispatch(setStatus(event?.data))
      }
      else if(checkDataType === 'callBackCommand'){
        const data = event?.data.split('/')
        dispatch(changeMcAvtiveCommand(JSON.parse(data[2])))
      }
      else if(checkDataType === 'McConnected' || checkDataType === 'McDisConnected' ){
        const data = event?.data.split('/')
        const item = {isConnected: data[0] === 'McConnected' ? true : false, guid: data[2]}
        dispatch(setIsConnectedMc(item))
      }

    };
  }

  const disconnected = () =>{
    if(socket){
      // socket?.current?.end();
      ws= null
      socket.current = null;
    }
  }

  const reconnected = () => {
    connected(path)
  }

  const sendMessage = (msg) => {
    if (isConnected) {
        socket.current.send(msg); // שולח הודעה לשרת
    }
  };

  return {
    connected,
    disconnected,
    sendMessage,
    isConnected,
    socket
  }
};

export default useWebSocket;