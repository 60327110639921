
const useMcCommand = () =>{

    const create_electra_1 = async (data, type, next) => {
        let command;
    
        const start = data?.ac?.commands?.startCommand
        const end = data?.ac?.commands?.endCommand
    
        const on = data?.ac?.commands?.power?.on
        const off = data?.ac?.commands?.power?.off
        const fanSpeedActive = data?.ac?.commands?.fanSpeed[data?.ac?.fanSpeedActive]
        const tempActive = data?.ac?.commands?.temperatures[data?.ac?.tempActive].command
        const modeActive = data?.ac?.commands?.modes[data?.ac?.modeActive]
    
        if(type === 'on') {
            const onComm =  data?.ac?.modeActive === 'vent' ? on["comm3"] : on["comm1"]
            command = `${start}${onComm}${modeActive[fanSpeedActive]}${tempActive}${end}`
        } else if(type === 'off') {
            const offComm =  data?.ac?.modeActive === 'vent' ? off["comm3"] : off["comm1"]
            command = `${start}${offComm}${modeActive[fanSpeedActive]}${tempActive}${end}`
        } else if(type === 'fan') {
            const isVent =  data?.ac?.modeActive === 'vent'
            const newFan = modeActive[next]
            command = `${start}${isVent ? "1" : ""}${newFan}${tempActive}${end}`
        } else if(type === 'mode') {
            const isVent =  next === 'vent'
            const newMode = data?.ac?.commands?.modes[next][fanSpeedActive]
            command = `${start}${isVent ? "1" : ""}${newMode}${tempActive}${end}`
        } else if(type === 'temp') {
            const isVent =  data?.ac?.modeActive === 'vent'
            const newTemp = data?.ac?.commands?.temperatures[next].command
            command = `${start}${isVent ? "1" : ""}${modeActive[fanSpeedActive]}${newTemp}${end}`
        }
        return command
    }
    
    return{ create_electra_1}
}
export default useMcCommand