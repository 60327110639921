import { Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Ac = ({mc}) =>{
    const { t } = useTranslation();
    const sensorList = useSelector((state) => state?.mc.sensorList);
    const [sensor1, setSensor1] = useState(null)
    const [sensor2, setSensor2] = useState(null)

    useEffect(() => {
        if(sensorList.length > 0){
            const sensor1Data = sensorList?.find(item => item?.guid === mc?.guid && item?.number === '1' )
            const sensor2Data = sensorList?.find(item => item?.guid === mc?.guid && item?.number === '2' )
            setSensor1(sensor1Data)
            setSensor2(sensor2Data)
        }
    }, [sensorList])

    const Item = ({title, value}) => {
        return (
            <Stack direction='row' spacing={0.5} alignItems='center'>
            <Typography fontWeight='bold'>{`${t(title)}:`}</Typography>
            <Typography>{value}</Typography>
            </Stack>
        )
    }

    return (
        <Stack spacing={2} padding={1}>
        <Stack direction='row' justifyContent='space-between'>
            <Stack alignItems='center'>
            <Typography variant="h6">{`${t('TEMP1')}`}</Typography>
            <Typography fontWeight='bold'>
                {sensor1?.value ? `${sensor1?.value}°` : t("LOADING_TO_DATA")}
            </Typography>
            </Stack>

            <Stack alignItems='center'>
            <Typography variant="h6">{`${t('TEMP2')}`}</Typography>
            <Typography fontWeight='bold'>
                {sensor2?.value ? `${sensor2?.value}°` : t("LOADING_TO_DATA")}
            </Typography>
            </Stack>

        </Stack>
            <Stack>
                <Typography sx={{textDecoration: "underline"}}>
                    {`${t('ACTIVE_DATA')}`}
                </Typography>

                <Item title='STATUS' value={mc?.ac?.turnOnDate ? t("ON_MC") : t("OFF_MC") }/>
                <Item title='TEMP_ACTIVE' value={mc?.ac?.tempActive ?? t("NO_DATA") }/>
                <Item title='FAN_SPEED' value={mc?.ac?.fanSpeedActive ?? t("NO_DATA") }/>
                <Item title='MODE_ACTIVE' value={mc?.ac?.modeActive ?? t("NO_DATA") }/>
            </Stack>
    </Stack>
    )
}
export default Ac