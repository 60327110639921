import { Divider, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import TextInput from "components/files/TextInput"
import { useTranslation } from "react-i18next";

const PowerCommands = ({errors, control}) => {
  const theme =useTheme()
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

return (
<Stack gap={2}>
    <Typography variant="h6" fontWeight='bold'>{t('POWER')}</Typography>

    {/* Commands on */}
    <Typography fontWeight='bold'>{t('ON')}</Typography>
      <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
        <TextInput
          name="onComm1"
          errors={errors}
          title="ON_COMM1"
          width="100%"
          control={control}
        />
        <TextInput
          name="onComm2"
          errors={errors}
          title="ON_COMM2"
          width="100%"
          control={control}
        />
        <TextInput
          name="onComm3"
          errors={errors}
          title="ON_COMM3"
          width="100%"
          control={control}
        />
      </Stack>

    {/* Commands Off */}
    <Typography fontWeight='bold'>{t('OFF')}</Typography>
      <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
        <TextInput
          name="offComm1"
          errors={errors}
          title="OFF_COMM1"
          width="100%"
          control={control}
        />
        <TextInput
          name="offComm2"
          errors={errors}
          title="OFF_COMM2"
          width="100%"
          control={control}
        />
        <TextInput
          name="offComm3"
          errors={errors}
          title="OFF_COMM3"
          width="100%"
          control={control}
        />
      </Stack>

    <Divider/>

    <Typography variant="h6" fontWeight='bold'>{t('MODES')}</Typography>

    <Typography fontWeight='bold'>{t('VENT')}</Typography>
      <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
        <TextInput
          name="ventLow"
          errors={errors}
          title="VENT_LOW"
          width="100%"
          control={control}
        />
        <TextInput
          name="ventMedium"
          errors={errors}
          title="VENT_MEDIUM"
          width="100%"
          control={control}
        />
        <TextInput
          name="ventHigh"
          errors={errors}
          title="VENT_HIGH"
          width="100%"
          control={control}
        />
        <TextInput
          name="ventTempException"
          errors={errors}
          title="VENT_TEMP_EXCEPTION"
          width="100%"
          control={control}
        />
      </Stack>

      <Typography fontWeight='bold'>{t('COOL')}</Typography>
      <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
        <TextInput
          name="coolLow"
          errors={errors}
          title="COOL_LOW"
          width="100%"
          control={control}
        />
        <TextInput
          name="coolMedium"
          errors={errors}
          title="COOL_MEDIUM"
          width="100%"
          control={control}
        />
        <TextInput
          name="coolHigh"
          errors={errors}
          title="COOL_HIGH"
          width="100%"
          control={control}
        />
      </Stack>

      <Typography fontWeight='bold'>{t('HEAT')}</Typography>
      <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
        <TextInput
          name="heatLow"
          errors={errors}
          title="HEAT_LOW"
          width="100%"
          control={control}
        />
        <TextInput
          name="heatMedium"
          errors={errors}
          title="HEAT_MEDIUM"
          width="100%"
          control={control}
        />
        <TextInput
          name="heatHigh"
          errors={errors}
          title="HEAT_HIGH"
          width="100%"
          control={control}
        />
      </Stack>
</Stack>
)
}
export default PowerCommands